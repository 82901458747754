import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import SectionTitle from "../../../../components/layout/SectionTitle";
import { COMPANY_MISSION_SECTION } from "../../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const CompanyMission = () => {
  return (
    <StyledSectionWrapper sectionId={COMPANY_MISSION_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Une mission" />
        <Grid container>
          <Typography fontSize={26} align="center" gutterBottom>
            <span className={classes.keyword}>Innovance Solutions </span> est
            née pour faciliter la vie des{" "}
            <span className={classes.keyword}>associations </span>
            qui doivent rendre des comptes aux financeurs en proposant un
            concept innovant : connecter les{" "}
            <span className={classes.keyword}>
              temps de l’équipe salariée
            </span>{" "}
            avec les <span className={classes.keyword}>données de gestion</span>{" "}
            issues des logiciels utilisés pour la comptabilité et la paie.
          </Typography>
          <Typography fontSize={22} align="center">
            Depuis plus de 10 ans nous répondons ainsi à des logiques et des
            problématiques de gestion spécifiques aux associations aussi bien
            pour rendre compte des financements publics que pour piloter en
            temps réel les ressources humaines et financières grâce à notre
            logiciel pour association.
          </Typography>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default CompanyMission;
