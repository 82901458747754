import SectionWrapper from "../../../../components/layout/SectionWrapper";
import styled from "../../../../util/styled";

const PREFIX = "AgendaFeatures";

export const classes = {
  container: `${PREFIX}-container`,
  keyword: `${PREFIX}-keyword`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.keyword}`]: {
    color: theme.palette.iaPink.main,
    fontWeight: 600,
  },
}));
