import Container from "@mui/material/Container";
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SectionTitle from "../../../../components/layout/SectionTitle";
import { COMPANY_CULTURE_SECTION } from "../../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const CompanyCulture = () => {
  return (
    <StyledSectionWrapper sectionId={COMPANY_CULTURE_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Une culture" />
        <Grid container>
          <Typography fontSize={26} align="center" gutterBottom>
            Chez Innovance, travailler pour des{" "}
            <span className={classes.keyword}>
              associations qui œuvrent pour l’intérêt{" "}
            </span>{" "}
            général implique une{" "}
            <span className={classes.keyword}>
              dimension humaine et sociétale forte{" "}
            </span>
            qui se retrouve dans le service que nous proposons à nos clients.
            Nous sommes un éditeur de logiciel qui privilégie un{" "}
            <span className={classes.keyword}>rapport humain </span>
            direct, connecté aux besoins des associations aussi spécifiques
            qu’ils soient, avec comme point d’orgue{" "}
            <span className={classes.keyword}>une qualité de service </span> qui
            fait notre marque de fabrique depuis plus de 10 ans.
          </Typography>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default CompanyCulture;
