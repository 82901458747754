import React from "react";
import Seo from "../../components/Seo";
import Culture from "../../views/Company/About/Culture";
import Hero from "../../views/Company/About/Hero";
import Mission from "../../views/Company/About/Mission";
import Professions from "../../views/Company/About/Professions";

const CompanyPage = () => {
  return (
    <React.Fragment>
      <Seo
        title="L'entreprise"
        description=" A propos d'Innovance Solutions, éditeur de logiciel pour association"
      />
      <Hero />
      <Mission />
      <Culture />
      <Professions />
    </React.Fragment>
  );
};

export default CompanyPage;
