import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import SectionTitle from "../../../../components/layout/SectionTitle";
import { COMPANY_PROFESSIONS_SECTION } from "../../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const CompanyProfessions = () => {
  return (
    <StyledSectionWrapper sectionId={COMPANY_PROFESSIONS_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Des métiers" />
        <Grid container>
          <Typography fontSize={26} align="center" gutterBottom>
            Innovance, c’est une équipe à{" "}
            <span className={classes.keyword}>taille humaine </span> qui
            rassemble des{" "}
            <span className={classes.keyword}>
              compétences plurielles et complémentaires
            </span>{" "}
            pour des solutions et des services au plus près des réalités de nos
            clients :
          </Typography>
          <Grid container spacing={4}>
            <Grid justifyContent="center" item md={6} sm={12}>
              <Typography fontSize={22} align="center" gutterBottom>
                Relations commerciales{" "}
              </Typography>
              <Typography fontSize={18} align="center" gutterBottom>
                C’est trouver de nouveaux clients mais aussi et surtout être à
                l’écoute des clients qui nous font confiance, les fidéliser par
                la prise en compte de leurs besoins spécifiques, y apporter des
                réponses que ce soit en terme de fonctionnalité ou par un
                accompagnement personnalisé.
              </Typography>
            </Grid>
            <Grid justifyContent="center" item md={6} sm={12}>
              <Typography fontSize={22} align="center" gutterBottom>
                Développement web{" "}
              </Typography>
              <Typography fontSize={18} align="center" gutterBottom>
                C’est développer des applications dans l’univers du web
                d’aujourd’hui grâce à des technologies récentes, toujours dans
                un soucis de pérennité, de scalabilité du code et de performance
                pour assurer à l’utilisateur une expérience optimale. C’est
                aussi trouver des solutions techniques pour répondre à des
                besoins métiers complexes, corriger des bugs rapidement quand
                ils interviennent et intervenir sur les environnements des
                clients pour les dépanner en temps réel.
              </Typography>
            </Grid>
            <Grid justifyContent="center" item md={6} sm={12}>
              <Typography fontSize={22} align="center" gutterBottom>
                Ingénierie de formation{" "}
              </Typography>
              <Typography fontSize={18} align="center" gutterBottom>
                C’est concevoir des modules de formation en alliant une parfaite
                connaissance du terrain associatif, une connaissance métier sur
                les problématiques de gestion, de comptabilité et de suivi du
                temps et une expertise sur les solutions que nous concevons.
                C’est aussi assurer le suivi et toutes les démarches
                administratives, avant et après les formations.
              </Typography>
            </Grid>
            <Grid justifyContent="center" item md={6} sm={12}>
              <Typography fontSize={22} align="center" gutterBottom>
                Secteur associatif{" "}
              </Typography>
              <Typography fontSize={18} align="center" gutterBottom>
                C’est connaître les particularités du monde associatif, de ses
                rouages à ses modes de gouvernance, en passant par ses
                particularités comptables et ses financeurs. Nous intégrons dans
                notre équipe des personnes issues d’association qui ont été en
                prise directe avec ces problématiques, pour les intégrer dans la
                conception de nos solutions et toujours être dans une réalité de
                terrain.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default CompanyProfessions;
