import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { globalClasses } from "../../../../components/layout/MainLayout/styles";
import { COMPANY_HERO_SECTION } from "../../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const CompanyHero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper
      padding="normal"
      sectionId={COMPANY_HERO_SECTION}
      className={globalClasses.innoBlueGradient}
    >
      <Container>
        <Grid
          item
          sm={12}
          justifyContent={isMobile ? "center" : "flex-start"}
          container
          direction="column"
        >
          <Typography
            variant="h3"
            component="h1"
            align={isMobile ? "center" : "inherit"}
            gutterBottom
            className={classes.typo}
          >
            L'entreprise
          </Typography>
          <Typography
            variant="body1"
            align={isMobile ? "center" : "inherit"}
            gutterBottom
            className={classes.typo}
          >
            A propos d'Innovance
          </Typography>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default CompanyHero;
